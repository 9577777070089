// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-acceptable-use-policy-tsx": () => import("./../../../src/pages/acceptable-use-policy.tsx" /* webpackChunkName: "component---src-pages-acceptable-use-policy-tsx" */),
  "component---src-pages-add-ons-tsx": () => import("./../../../src/pages/add-ons.tsx" /* webpackChunkName: "component---src-pages-add-ons-tsx" */),
  "component---src-pages-cookie-policy-tsx": () => import("./../../../src/pages/cookie-policy.tsx" /* webpackChunkName: "component---src-pages-cookie-policy-tsx" */),
  "component---src-pages-finalized-ingredients-tsx": () => import("./../../../src/pages/finalized-ingredients.tsx" /* webpackChunkName: "component---src-pages-finalized-ingredients-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-ingredient-reports-tsx": () => import("./../../../src/pages/ingredient-reports.tsx" /* webpackChunkName: "component---src-pages-ingredient-reports-tsx" */),
  "component---src-pages-investors-tsx": () => import("./../../../src/pages/investors.tsx" /* webpackChunkName: "component---src-pages-investors-tsx" */),
  "component---src-pages-lp-f-1-plant-based-recipes-description-tsx": () => import("./../../../src/pages/lp/f1/plant-based-recipes-description.tsx" /* webpackChunkName: "component---src-pages-lp-f-1-plant-based-recipes-description-tsx" */),
  "component---src-pages-lp-f-1-plant-based-recipes-email-tsx": () => import("./../../../src/pages/lp/f1/plant-based-recipes-email.tsx" /* webpackChunkName: "component---src-pages-lp-f-1-plant-based-recipes-email-tsx" */),
  "component---src-pages-lp-plant-based-grocery-list-copy-tsx": () => import("./../../../src/pages/lp/plant-based-grocery-list copy.tsx" /* webpackChunkName: "component---src-pages-lp-plant-based-grocery-list-copy-tsx" */),
  "component---src-pages-lp-plant-based-grocery-list-tsx": () => import("./../../../src/pages/lp/plant-based-grocery-list.tsx" /* webpackChunkName: "component---src-pages-lp-plant-based-grocery-list-tsx" */),
  "component---src-pages-lp-plant-based-meal-kits-tsx": () => import("./../../../src/pages/lp/plant-based-meal-kits.tsx" /* webpackChunkName: "component---src-pages-lp-plant-based-meal-kits-tsx" */),
  "component---src-pages-lp-plant-based-meal-plan-tsx": () => import("./../../../src/pages/lp/plant-based-meal-plan.tsx" /* webpackChunkName: "component---src-pages-lp-plant-based-meal-plan-tsx" */),
  "component---src-pages-lp-plant-based-recipes-tsx": () => import("./../../../src/pages/lp/plant-based-recipes.tsx" /* webpackChunkName: "component---src-pages-lp-plant-based-recipes-tsx" */),
  "component---src-pages-lp-plant-based-starter-tsx": () => import("./../../../src/pages/lp/plant-based-starter.tsx" /* webpackChunkName: "component---src-pages-lp-plant-based-starter-tsx" */),
  "component---src-pages-plantry-tsx": () => import("./../../../src/pages/plantry.tsx" /* webpackChunkName: "component---src-pages-plantry-tsx" */),
  "component---src-pages-privacy-tsx": () => import("./../../../src/pages/privacy.tsx" /* webpackChunkName: "component---src-pages-privacy-tsx" */),
  "component---src-pages-reviews-review-tsx": () => import("./../../../src/pages/reviews/review.tsx" /* webpackChunkName: "component---src-pages-reviews-review-tsx" */),
  "component---src-pages-reviews-thank-you-tsx": () => import("./../../../src/pages/reviews/thank-you.tsx" /* webpackChunkName: "component---src-pages-reviews-thank-you-tsx" */),
  "component---src-pages-shop-all-recipes-tsx": () => import("./../../../src/pages/shop-all-recipes.tsx" /* webpackChunkName: "component---src-pages-shop-all-recipes-tsx" */),
  "component---src-pages-shop-now-tsx": () => import("./../../../src/pages/shop-now.tsx" /* webpackChunkName: "component---src-pages-shop-now-tsx" */),
  "component---src-pages-shop-recipes-tsx": () => import("./../../../src/pages/shop-recipes.tsx" /* webpackChunkName: "component---src-pages-shop-recipes-tsx" */),
  "component---src-pages-terms-of-service-tsx": () => import("./../../../src/pages/terms-of-service.tsx" /* webpackChunkName: "component---src-pages-terms-of-service-tsx" */),
  "component---src-pages-thank-you-tsx": () => import("./../../../src/pages/thank-you.tsx" /* webpackChunkName: "component---src-pages-thank-you-tsx" */),
  "component---src-templates-product-tsx": () => import("./../../../src/templates/product.tsx" /* webpackChunkName: "component---src-templates-product-tsx" */),
  "component---src-templates-recipe-tsx": () => import("./../../../src/templates/recipe.tsx" /* webpackChunkName: "component---src-templates-recipe-tsx" */)
}

